let contentData;
let dataToShow;
let isSlideShowActive = false;
let playlistData;
document.addEventListener("DOMContentLoaded", async () => {
  schedulePageReload();
  let url = window.location.pathname.substring(1);
  if (url) {
    localStorage.setItem("screenID", url);
  }

  if (navigator.onLine) {
    const { subscribeToScreen } = await import("./services/firebaseService.js");
    try {
      const screenId = localStorage.getItem("screenID");
      const fireBaseData = await subscribeToScreen(screenId, handleDataChange);
    } catch (error) {
      console.error("Fehler beim Abrufen der Firebase-Daten:", error);
    }
  } else {
    console.log("offline");
    // Offline-Handling falls nötig
  }
});

async function handleDataChange(dataSet) {
  contentData = [];
  playlistData = [];
  dataToShow = [];
  const { getDocumentById } = await import("./services/firebaseService.js");
  let data = await getDataSetInRange(dataSet);
  const parts = data.screenData[0].screenData.split("-", 2);
  const part1 = parts[0];
  playlistData = await getDocumentById("playlists", part1);
  if (playlistData == null) {
    playlistData = await getDocumentById("contentData", part1);
  }
  contentData = playlistData.data;
  filteredData = checkImagesAreInRange(playlistData.data);
  dataToShow = [];
  if (contentData.length > 0) {
    for (let data of contentData) {
      let records = [];
      for (let recordData of data.contentData) {
        let record = await getDocumentById("contentData", recordData.id);
        records.push({
          recordUrl: record.data.url,
          type: record.data.contentType,
          time: recordData.time,
        });
      }
      dataToShow.push({
        data: records,
        startTime: convertToComparableTime(data.startTime),
        endTime: convertToComparableTime(data.endTime),
      });
    }
  } else {
    dataToShow.push({
      data: contentData.url,
      type: contentData.contentType,
    });
  }
  loadDataToTheDom();
}
function getDataSetInRange(data) {
  const now = new Date().getTime();
  data.screenData = data.screenData.filter((dataSet) => {
    if (dataSet.startDate && dataSet.endDate) {
      const startTime = dataSet.startDate.seconds * 1000;
      const endTime = dataSet.endDate.seconds * 1000;
      if (startTime < now && endTime >= now) {
        return true; // Behalte den Datensatz
      } else {
        return false; // Entferne den Datensatz
      }
    } else {
      return true; // Entferne den Datensatz, wenn startDate oder endDate fehlen
    }
  });

  return data;
}

function checkImagesAreInRange(data) {
  const now = new Date().getTime();
  const currentDay = new Date().getDay();
  if (data[0]?.contentData) {
    data[0].contentData = data[0].contentData.filter((dataSet) => {
      if (dataSet.startDate && dataSet.endDate) {
        const startTime = dataSet.startDate.seconds * 1000;
        const endTime = dataSet.endDate.seconds * 1000;

        const isInTimeRange = startTime < now && endTime > now;
        const isWeekDayMatch = dataSet.weekDays
          ? dataSet.weekDays.includes(currentDay)
          : true;
        if (isInTimeRange && isWeekDayMatch) {
          return true;
        } else {
          return false;
        }
      } else {
        const isWeekDayMatch = dataSet.weekDays
          ? dataSet.weekDays.includes(currentDay)
          : true;
        if (isWeekDayMatch) {
          return true;
        } else {
          return false;
        }
      }
    });
  } else {
  }
}

function setInnerHTML(elm, html) {
  elm.innerHTML = html;

  Array.from(elm.querySelectorAll("script")).forEach((oldScriptEl) => {
    const newScriptEl = document.createElement("script");

    Array.from(oldScriptEl.attributes).forEach((attr) => {
      newScriptEl.setAttribute(attr.name, attr.value);
    });

    const scriptText = document.createTextNode(oldScriptEl.innerHTML);
    newScriptEl.appendChild(scriptText);

    oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
  });
}
function convertToComparableTime(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60;
}
function getCurrentTimeInMinutes() {
  const now = new Date();
  return now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
}

function loadDataToTheDom() {
  let container = document.getElementById("screen");
  container.innerHTML = ``;
  for (let timespan of dataToShow) {
    let i = 0;
    if (Array.isArray(timespan.data)) {
      for (let dataUrl of timespan.data) {
        let idName = timespan.startTime + "-" + timespan.endTime + "-i=" + i;
        switch (dataUrl.type) {
          case "jpg": {
            let image = document.createElement("img");
            image.setAttribute("id", idName);
            image.src = dataUrl.recordUrl;
            image.setAttribute("class", "mySlideImage dataSet");
            container.appendChild(image);
            break;
          }
          case "png": {
            let image = document.createElement("img");
            image.setAttribute("id", idName);
            image.src = dataUrl.recordUrl;
            image.setAttribute("class", "mySlideImage dataSet");
            container.appendChild(image);
            break;
          }
          case "mp4": {
            let videoElement = document.createElement("video");
            videoElement.id = idName;
            videoElement.autoplay = true;
            videoElement.loop = true;
            videoElement.muted = true;
            videoElement.setAttribute("class", "dataSet");
            let sourceElement = document.createElement("source");
            sourceElement.src = dataUrl.recordUrl;
            sourceElement.type = "video/mp4";
            videoElement.appendChild(sourceElement);
            container.appendChild(videoElement);
            break;
          }
          case "HTML": {
            let htmlElement = document.createElement("div");
            htmlElement.setAttribute("id", idName);
            htmlElement.setAttribute("class", "HtmlCustom dataSet");
            setInnerHTML(htmlElement, dataUrl.recordUrl);
            container.appendChild(htmlElement);
            break;
          }
          default: {
            console.log("fehler");
          }
        }
        i++;
      }
    } else {
      let idName = timespan.startTime + "-" + timespan.endTime + "-i=" + i;
      switch (dataToShow[0].type) {
        case "jpg": {
          let image = document.createElement("img");
          image.setAttribute("id", idName);
          image.src = dataToShow[0].data;
          image.setAttribute("class", "mySlideImage dataSet");
          container.appendChild(image);
          break;
        }
        case "png": {
          let image = document.createElement("img");
          image.setAttribute("id", idName);
          image.src = dataToShow[0].data;
          image.setAttribute("class", "mySlideImage dataSet");
          container.appendChild(image);
          break;
        }
        case "mp4": {
          let videoElement = document.createElement("video");
          videoElement.id = idName;
          videoElement.autoplay = true;
          videoElement.loop = true;
          videoElement.muted = true;
          videoElement.setAttribute("class", "dataSet");
          let sourceElement = document.createElement("source");
          sourceElement.src = dataToShow[0].data;
          sourceElement.type = "video/mp4";
          videoElement.appendChild(sourceElement);
          container.appendChild(videoElement);
          break;
        }
        case "HTML": {
          let htmlElement = document.createElement("div");
          htmlElement.setAttribute("id", idName);
          htmlElement.setAttribute("class", "customHtml dataSet");
          setInnerHTML(htmlElement, dataToShow[0].data);
          container.appendChild(htmlElement);
          break;
        }
        default: {
          console.log("fehler");
        }
      }
    }
  }
  startLoop();
}
let startLoopTimeoutId;
function startLoop() {
  start();

  function start() {
    clearTimeout(this.startLoopTimeoutId);
    const now = getCurrentTimeInSeconds();
    let timeToNextSpan = Infinity;
    let timeSpanFounded = false;
    let whileIndex = 0;

    while (whileIndex < dataToShow.length && !timeSpanFounded) {
      const timeSpan = dataToShow[whileIndex];
      const startTime = timeSpan.startTime;
      const endTime = timeSpan.endTime;

      if (endTime <= startTime) {
        console.log("over midnight");
        // Zeitspanne geht über Mitternacht
        if (now >= startTime || now < endTime) {
          if (now >= startTime) {
            // Zeit bis Mitternacht und dann bis zum Endzeitpunkt am nächsten Tag
            const timeToMidnight = 86400 - now;
            const timeFromStartOfNextDay = endTime;
            timeToNextSpan = timeToMidnight + timeFromStartOfNextDay;
          } else if (now < endTime) {
            // Zeit vom Start des Tages bis zur Endzeit
            timeToNextSpan = endTime - now;
          }
          timeSpanFounded = true;
          loopInTime(timeSpan);
        }
      } else {
        console.log("same Day");
        // Zeitspanne liegt innerhalb des gleichen Tages
        if (now >= startTime && now < endTime) {
          timeToNextSpan = endTime - now;
          timeSpanFounded = true;
          loopInTime(timeSpan);
        } else if (now < startTime) {
          console.log("other");
          // Zeitspanne beginnt später am selben Tag
          timeToNextSpan = startTime - now;
        }
      }

      whileIndex++;
    }

    let timeToNext = timeToNextSpan * 1000; // Sekunden in Millisekunden umwandeln
    if (timeToNext < 10000) {
      console.log("wait");
      timeToNext = 10000; // Mindestwartezeit von 1 Minute
    }
    this.startLoopTimeoutId = setTimeout(start, timeToNext);
  }
}

function getCurrentTimeInSeconds() {
  const now = new Date();
  return now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds(); // Zeit in Sekunden seit Mitternacht
}

function loopInTime(data) {
  isSlideShowActive = false;
  let elements = document.querySelectorAll(".dataSet");
  elements.forEach((element) => {
    element.style.zIndex = 0;
  });
  if (data.data.length > 1) {
    isSlideShowActive = true;
    runSlideShow(data);
  } else {
    let idName = data.startTime + "-" + data.endTime + "-i=" + 0;
    document.getElementById(idName).style.zIndex = 1;
  }
}
let timeOutId;
function runSlideShow(data) {
  if (isSlideShowActive) {
    let slideShowIndex = 0;
    const dataLength = data.data.length;
    nextSlide();
    function nextSlide() {
      if (isSlideShowActive) {
        clearTimeout(this.timeOutId);
        let slideShowIndexInstanz = slideShowIndex;
        nextIndex = (slideShowIndexInstanz + 1) % dataLength;
        lastIndex = (slideShowIndexInstanz - 1 + dataLength) % dataLength;
        let currentIdName =
          data.startTime + "-" + data.endTime + "-i=" + slideShowIndex;
        //@todo Animation
        const lastIdName = `${data.startTime}-${data.endTime}-i=${lastIndex}`;
        let elements = document.querySelectorAll(".dataSet");
        elements.forEach((element) => {
          element.style.zIndex = 0;
          //@todo Animation
          // element.classList.remove("lastSlide", "active");
        });
        document.getElementById(lastIdName).style.zIndex = 1;
        //@todo Animation
        // document.getElementById(lastIdName).classList.add("lastSlide");
        // document.getElementById(currentIdName).classList.add("active");
        document.getElementById(currentIdName).style.zIndex = 2;
        let timeIndex = slideShowIndex;
        slideShowIndex = nextIndex;
        console.log(data.data[timeIndex].time);
        this.timeOutId = setTimeout(
          nextSlide,
          data.data[timeIndex].time * 1000
        );
      }
    }
  }
}
let reloadTimeOutId;
function schedulePageReload() {
  clearTimeout(this.reloadTimeOutId);
  // Funktion zum Neuladen der Seite
  function reloadPage() {
    window.location.reload();
  }

  // Funktion zur Berechnung der verbleibenden Zeit bis 00:10 Uhr
  function calculateTimeToReload() {
    const now = new Date();
    const targetHour = 0; // 00 Uhr
    const targetMinute = 1; // 10 Minuten

    let nextReload = new Date(now);
    nextReload.setHours(targetHour);
    nextReload.setMinutes(targetMinute);
    nextReload.setSeconds(0);
    nextReload.setMilliseconds(0);

    if (now > nextReload) {
      // Wenn die Zeit bereits vorbei ist, auf den nächsten Tag setzen
      nextReload.setDate(nextReload.getDate() + 1);
    }

    return nextReload - now; // Zeit in Millisekunden bis zur nächsten Neuladung
  }

  // Berechnung der Zeit bis zum nächsten Neuladen
  const timeToReload = calculateTimeToReload();

  // Planen der Neuladung zur berechneten Zeit
  this.reloadTimeOutId = setTimeout(() => {
    reloadPage();
    // Nach dem Neuladen wird der Timer zurückgesetzt, um sicherzustellen, dass es jeden Tag wieder um 00:10 Uhr geschieht
    setInterval(reloadPage, 24 * 60 * 60 * 1000); // Alle 24 Stunden
  }, timeToReload);
}
